import React from 'react'
import Header from '../../components/Header'
import Home from '../components/Hero'
import News from '../../components/News'
function BlogPage() {
  return (
    <div className='flex flex-col'>
        <Header/>
        <Home/>
        <News/>
    </div>
  )
}

export default BlogPage