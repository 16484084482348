import React from 'react'

function ErrorPage() {
  return (
    <div>
        <h2>This page is still under construction, come back later!!!!!</h2>
    </div>
  )
}

export default ErrorPage