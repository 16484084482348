import React from 'react'
import ServiceCard from "../components/ServiceCard"
import '../style/style.css'
function Services() {
  return (
   
    <div id="services" className='service flex flex-col justify-center  my-6 p-5 items-center'>
      <h1 className='text-4xl font-extrabold text-white pb-8'>Our Services</h1>
      <div className='flex items-center justify-center mb-12 lg:flex-row sm:flex-col sm:mb-4'>
        <ServiceCard desc={"Like so many organizations these days, Autodesk is a company in "+
            "transition. It was until recently a traditional boxed software companys" +
            "selling licenses. Yet its own business model disruption is only part" +
            "of the story"} title={`Water Body Cleaning`} />
        <ServiceCard desc={"Like so many organizations these days, Autodesk is a company in "+
            "transition. It was until recently a traditional boxed software companys" +
            "selling licenses. Yet its own business model disruption is only part" +
            "of the story"} title={"Forest Restoration"} />
        <ServiceCard desc={"Like so many organizations these days, Autodesk is a company in "+
            "transition. It was until recently a traditional boxed software companys" +
            "selling licenses. Yet its own business model disruption is only part" +
            "of the story"} title={"Waste Management"} />
      </div>
      
    </div>
 
  )
}

export default Services