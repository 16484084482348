import React from "react";
import "../style/style.css";
import { Typography } from "@material-tailwind/react";
function Aspiration() {
  return (
    <section id="approach" class="bg-white mb-6">
      <div class="px-4 lg:flex lg:flex-row h-fit-content max-w-screen-xl text-center py-2 lg:py-2 md:flex md:flex-col sm:flex sm:flex-col">
        <div className="lg:w-1/2 p-6 flex flex-col">
          <h1 className="lg:text-4xl text-2xl font-extrabold text-center mt-6 text-green-forth ml-8 mb-4 pb-2">
            Our Approach
          </h1>
          <Typography
            variant="h5"
            className="mb-2 text-left text-green-forth font-bold text-lg"
          >
            We offer a comprehensive suite of services, including water body
            cleaning, forest restoration, and waste management. Through the
            adoption of innovative and sustainable technologies, we're
            minimizing our ecological footprint while optimizing the efficiency
            of our operations.
          </Typography>
          <div className="w-full h-2 flex justify-center items-center">
            <div className="h-1 w-28 bg-sec-white align-middle"></div>
          </div>
        </div>
        <div className="lg:w-1/2 p-6 ml-6">
          <h1 className="lg:text-4xl text-2xl font-extrabold mt-6 text-green-forth ml-8 mb-4 pb-2">
            Our Approach to Outreach
          </h1>
          <Typography
            variant="h5"
            className="mb-2 text-left text-green-forth font-bold text-lg"
          >
            From digital marketing to direct mail campaigns, we're utilizing
            diverse strategies to spread the word about our services. Attending
            industry events and building strong client relationships are core to
            our success.
          </Typography>
          <div className="w-full h-2 flex justify-center items-center">
            <div className="h-1 w-28 bg-sec-white align-middle"></div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Aspiration;
