import React from 'react'
import Header from "../components/Header";
import Footer1 from "../components/Footer1";
import Hero from "../components/Hero";
import Mvision from "../components/Mvision";
import GuidingP from "../components/GuidingP";
import About from "../components/About";
// import Partners from "../components/Partners";
import Services from "../layout/Services";
import Approach from "../components/Approach";
import Aspiration from "../components/Aspiration";
import Contact from "../components/Contact";
import News from '../components/News';
import Why from "../components/Why";
function AppLayout() {
  return (
    <div> 
    <Header/>
    <Hero/>
    <About/>
    
    <Services/>
    <Mvision/>
    <Approach/>
    <div>
      <GuidingP/>
    </div>
    {/* <Partners id='partners'/> */}
    <Aspiration/>
    <Why/>
    <News/>
    <Contact/>
    <Footer1/>
   </div>
  )
}

export default AppLayout