import React from 'react'
import axios from 'axios';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
function AllComments() {
    const params = useParams();
    const id = params._id
    const [post, setPost] = useState([]);
    // const [isLoading, setIsloading] = useState(false);
    
 
    useEffect(() => {
      const baseURL = `http://localhost:8000/post/${id}`;
      const getPost = async () => {
        try {
          let res = await axios.get(baseURL);
          setPost(res.data)
         
        } catch (error) {
          alert(error);
        }
        
      };
      getPost();
    }, [id]);

   
  return (
   <>
    { post.map((post, i)=>{
        return ( 
                    <div className="flex  items-center w-full px-6 py-6 mx-auto mt-10 bg-white border border-gray-200 rounded-lg sm:px-8 md:px-12 sm:py-8 sm:shadow lg:w-5/6 xl:w-2/3">
                    <div  className="flex items-center mt-6 mb-6 mr-6">
                      <img
                        src="https://avatars.githubusercontent.com/u/71964085?v=4"
                        alt="avatar"
                        className="hidden object-cover w-14 h-14 mx-4 rounded-full sm:block"
                      />
                    </div>
      
                    <div>
                      <h3 className="text-lg font-bold text-purple-500 sm:text-xl md:text-2xl">
                        {post.username}
                      </h3>
                      <p className="text-sm font-bold text-gray-300">{post.updatedAt}</p>
                      
                       {
                       
                          <p className="mt-2 text-base text-gray-600 sm:text-lg md:text-normal">{post.comment[i]}</p>
                       
                      
                       
                      }
                        
                      
                      
                    </div>
                  </div>
                  ) 
      
                })}
        
  </>
  )
}

export default AllComments