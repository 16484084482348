import {
    Card,
    
    CardBody,
    Typography,
    Button,
  } from "@material-tailwind/react";
   
function ServiceCard({title, desc}) {
    return (
      <Card className="w-80 py-20 bg-slate-200 lg:mx-4 my-2">
        
        <CardBody className="m-4">
       
          <Typography variant="h4" className="mb-2 relative text-green-forth font-bold text-lg" >
            {title}
          </Typography>
          <Typography className="text-sec-white overflow-hidden text-lg font-normal">
            {desc}
          </Typography>
          <div href="#" className="inline-block">
            <Button variant="text" className="flex pt-14 text-green-forth items-center gap-2">
              Learn More
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
                className="h-4 w-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                />
              </svg>
            </Button>
          </div>
        </CardBody>
      </Card>
    );
  }
  export default ServiceCard;