import React from 'react'
import "../style/style.css"
function Approach() {
  return (
    <div id="aspi" className='flex flex-col w-screen'>
     
      <div className='w-full  lg:grid lg:grid-cols-2 lg:py-4 lg:px-6'>
        <div className='flex flex-col'>
          <h1 className='text-4xl font-extrabold mt-6 text-green-forth ml-8 mb-4 pb-2'>Our Aspiration</h1>
          <h3 className='aspi text-green-forth  md:w-full sm:w-full  text-xl flex items-center ml-8 font-bold'>We envision a world where thriving forests and clean water bodies are valued treasures. Through sustainability, innovation, and positive change, we're becoming global leaders in environmental restoration. Join Us in Forging a Better Future: "Guarding Nature, Forging a Better Future" is more than a slogan—it's a call to action. Let's work together to ensure a harmonious coexistence between humanity and nature</h3>
        </div>
           
          <div className='aspi-pic w-1/2 lg:h-96 flex items-center sm:w-full mt-4 mb-6 justify-center mr-8'>
            <div className='circle-1 rounded-full absolute left-12  w-40 h-40 lg:w-80 lg:h-80 bg-green-700'></div>
            <div className='circle-2 rounded-full lg:w-80 relative w-40 h-40 left-40 lg:h-80  bg-green-700'></div>
          </div>
      </div>
    
       
    </div>
  )
}

export default Approach