import React, {  useState } from 'react'
import axios from 'axios'
import toast from 'react-hot-toast';
import { useParams  } from 'react-router-dom'

const AddComment =() => {
   
    const [comment, setComment] = useState([])
    const params = useParams()
    const data = {comment}
    const url = `http://localhost:8000/comment/${params._id}`
    async function saveComment(e){
        e.preventDefault();
        try {
          await axios.patch(url, data)
          .then(res=>{
            if (res.status === 200) {
             alert('Comment saved successfully')
            }
            window.location.reload(false);
            
          }).error((er)=>{
            toast('Something went wrong, try again sometime.',{
              duration: 1000,
              position: 'top-center'
            })
          })
         
        } catch (error) {
          
        }
        
    }
  return (
    <div className="w-full mt-16 md:mt-0 ">
				   <div className="relative z-40 h-auto p-8 py-10 overflow-hidden bg-white border-b-2 border-gray-300 rounded-lg shadow-2xl px-7">
					   <h3 className="mb-6 text-2xl font-medium text-center">Write a comment</h3>
					   <textarea value={comment} onChange={e => setComment(e.target.value)} name="comment" className="w-full px-4 py-3 mb-4 border  border-transparent border-gray-200 rounded-lg focus:ring focus:ring-blue-500 focus:outline-none" placeholder="Write your comment" rows="5" cols="33"></textarea>
					   <button onClick={saveComment} name="submit" className=" text-white px-4 py-3 bg-blue-500  rounded-lg">Submit comment</button>
				   </div>
			   </div>
  )
}

export default AddComment