import React from "react";
// import Principle from "../assets/markus-spiske-hxmi7JXvXYo-unsplash.jpg"
import "../style/blob.scss";
import "../style/style.css";
import { Typography } from "@material-tailwind/react";
function GuidingP() {
  return (
    <section id="guiding" class="bg-guidingp dark:bg-gray-900 mx-auto">
      <div className="flex flex-col justify-center items-center w-full mb-5">
        <h1 className="lg:text-4xl text-2xl font-extrabold text-center mt-6 text-green-forth ml-8 mb-4 pb-2">
          Our Guiding Principles
        </h1>

        <Typography
          variant="h4"
          className="mb-4 relative text-base text-center text-green-forth font-bold lg:text-lg"
        >
          At Nature Guard Ltd., we uphold essential values that guide our
          actions:
        </Typography>
        <div className="w-full lg:grid  lg:grid-cols-2 lg:grid-rows-1 lg:gap-4 relative p-4 lg:left-32 lg:flex-row sm: flex-col sm:mb-4">
          <div className="lg:w-1/2 relative ">
            <Typography
              variant="h5"
              className="mb-2 text-green-forth font-bold text-lg"
            >
              Environmental Stewardship:
            </Typography>
            <p>
              We take responsibility for safeguarding the natural environment,
              promoting conservation, and leaving a lasting positive legacy for
              future generations.
            </p>
          </div>
          <div className="lg:w-1/2">
            <Typography
              variant="h5"
              className="mb-2 text-green-forth font-bold text-lg"
            >
              Innovation:
            </Typography>
            <p>
              We continuously seek creative and cutting-edge solutions to
              address environmental challenges, pushing the boundaries of what
              is possible
            </p>
          </div>
          <div className="col-start-2 row-start-2 lg:w-1/2">
            <Typography
              variant="h5"
              className="mb-2 text-green-forth font-bold text-lg"
            >
              Collaboration:
            </Typography>
            <p>
              We believe that working together with partners, clients,
              communities, and organizations is fundamental to achieving
              meaningful and lasting environmental change
            </p>
          </div>
          <div className="col-start-1 row-start-2 lg:w-1/2">
            <Typography
              variant="h5"
              className="mb-2 text-green-forth font-bold text-lg"
            >
              Integrity:
            </Typography>
            <p>
              We conduct ourselves with the highest level of honesty, ethics,
              and transparency, ensuring trust and reliability in all our
              actions.
            </p>
          </div>
          <div className="row-start-3 lg:w-1/2">
            <Typography
              variant="h5"
              className="mb-2 text-green-forth font-bold text-lg"
            >
              Empowerment:
            </Typography>
            <p>
              We empower local communities to actively participate in
              environmental restoration efforts, recognizing their essential
              role in preserving ecosystems.
            </p>
          </div>
          <div className="row-start-3 lg:w-1/2">
            <Typography
              variant="h5"
              className="mb-2 text-green-forth font-bold text-lg"
            >
              Sustainability:
            </Typography>
            <p>
              We prioritize sustainability in all aspects of our operations,
              minimizing our environmental footprint while maximizing long-term
              positive impacts.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default GuidingP;
