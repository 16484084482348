import React from "react";
import{BsFacebook} from 'react-icons/bs'
import {BsTwitter} from 'react-icons/bs'
import {BsInstagram} from 'react-icons/bs'
import {BsPinterest} from 'react-icons/bs'
import { Link } from "react-router-dom";
const Footer1 = () => {
    return (
      <>
        <footer className="relative bg-second-color text-white pt-8 pb-6">
  <div className="container">
    <div className="flex text-left lg:text-left">
      <div className="w-full lg:w-full px-4">
        <h4 className="text-3xl fonat-semibold text-blueGray-700">Let's keep in touch!</h4>
        <h5 className="text-lg mt-0 mb-2 text-blueGray-600">
          Find us on any of these platforms, we respond 1-2 business days.
        </h5>
        <div className="mt-6 lg:mb-0 flex mb-6">
          <button className="bg-white text-green-forth shadow-lg font-normal h-10 w-10 flex items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2" type="button">
            <BsFacebook/>
          </button>
          <button className="bg-white text-green-forth flex shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2" type="button">
            <BsTwitter/>
          </button>
            <button className="bg-white flex text-green-forth shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2" type="button">
            <BsInstagram/>
           </button>
            <button className="bg-white flex text-green-forth shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2" type="button">
            <BsPinterest/>
          </button>
        </div>
      </div>
      <div className="w-full lg:w-6/12 px-4">
        <div className="flex flex-wrap items-top mb-6">
          <div className="w-full lg:w-4/12 px-4 ml-auto">
            <span className="block uppercase text-blueGray-500 text-sm font-semibold mb-2">Useful Links</span>
            <ul className="list-unstyled">
              <li>
                <a href="#about" className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm">About Us</a>
              </li>
              <li>
                <Link to="/errorpage" className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm" >Blog</Link>
              </li>
              {/* <li>
                <a className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm" href="https://www.github.com/creativetimofficial?ref=njs-profile">Github</a>
              </li> */}
              <li>
                <a href="#services" className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm">Our Services</a>
              </li>
            </ul>
          </div>
          <div className="w-full lg:w-4/12 px-4">
            <span className="block uppercase text-blueGray-500 text-sm font-semibold mb-2">Other Resources</span>
            <ul className="list-unstyled">
              <li>
                <a className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm" href="https://github.com/creativetimofficial/notus-js/blob/main/LICENSE.md?ref=njs-profile">License</a>
              </li>
              <li>
                <a className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm" href="https://creative-tim.com/terms?ref=njs-profile">Terms &amp; Conditions</a>
              </li>
              <li>
                <a className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm" href="https://creative-tim.com/privacy?ref=njs-profile">Privacy Policy</a>
              </li>
              <li>
                <a className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm" href="https://creative-tim.com/contact-us?ref=njs-profile">Contact Us</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    
  
  </div>
  
</footer>
<div class="flex flex-wrap bg-white w-full text-deep-green items-center md:justify-between justify-center">
      <div class="w-full md:w-full px-4 mx-auto text-center">
        <div class="text-sm text-blueGray-500 font-semibold py-2">
          Copyright © <span id="get-current-year">2023</span><div class="text-blueGray-500 hover:text-gray-800" target="_blank"></div>
          <div class="text-blueGray-500 hover:text-blueGray-800"> Nature Guard</div>.
        </div>
      </div>
    </div>
</>
    );
};

export default Footer1;
