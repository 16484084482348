import React from "react";
import logo from "../assets/Logo-NGLtd.png"
import '../style/style.css'
import { Link } from "react-router-dom";
const  Header = (props) => {
    const [open, setOpen] = React.useState(false);
    const [flyer, setFlyer] = React.useState(false);
    // const [setFlyerTwo] = React.useState(false);
  
    return (
      <>
       
        {/* bg should be white green */}
        <div className=" bg-white-green sticky top-0 z-20">
          <div className="max-w-7xl mx-auto px-4 sm:px-6">
            <div className="flex justify-between items-center border-b-2 border-gray-100 py-6 md:justify-start md:space-x-10">
              <div className="flex justify-start lg:w-0 lg:flex-1">
                <Link to={'/'}>
                  <span className="sr-only">Workflow</span>
                  <img
                    className="logo md:h-12 h-10 lg:h-14 w-auto sm:h-10"
                    src={logo}
                    alt=""
                  />
                </Link>
              </div>
              <div className="-mr-2 -my-2 md:hidden">
                <button
                  type="button"
                  className="bg-nav-bg rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                  onClick={() => setOpen(!open)}
                >
                  <span className="sr-only">Open menu</span>
                  {/* Heroicon name: outline/menu */}
                  <svg
                    className="h-6 w-6 bg-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                    
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                </button>
              </div>
              <nav className="hidden md:flex space-x-10">
                <div className="relative">
                  {/* Item active: "text-gray-900", Item inactive: "text-gray-500" */}
                  <button
                    type="button"
                    className="
                     group text-gray-500 inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 pb-8'
                    "
                    onClick={() => (setFlyer(!flyer))} //setFlyerTwo(false)
                  >
                    <span>About us</span>
                    {/*
                Heroicon name: solid/chevron-down
  
                Item active: "text-gray-600", Item inactive: "text-gray-400"
              */}
                    <svg
                      className={
                        
                        flyer === true
                          ? "transform  rotate-180 ml-2 h-5 w-5 text-gray-400 group-hover:text-nav-bg transition ease-out duration-200"
                          : "transform text-nav-bg rotate-0 transition ease-out duration-200 ml-2 h-5 w-5  group-hover:text-second-color"
                      }
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                  {/*
              'Solutions' flyout menu, show/hide based on flyout menu state.
  
              Entering: "transition ease-out duration-200"
                From: "opacity-0 translate-y-1"
                To: "opacity-100 translate-y-0"
              Leaving: "transition ease-in duration-150"
                From: "opacity-100 translate-y-0"
                To: "opacity-0 translate-y-1"
            */}
  
                  <div
                    onMouseLeave={() => setFlyer(false)}
                    className={
                      flyer
                        ? " opacity-100 translate-y-0 transition ease-out duration-200 absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
                        : " opacity-0 translate-y-1 absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
                    }
                  >
                    <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                      <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                      <a
                          href="#about"
                          className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                        >
                          {/* Heroicon name: outline/chart-bar */}
                          
                          <div className="ml-4">
                            <p className="text-base font-medium text-gray-900">
                              About us
                            </p>
                            <p className="mt-1 text-sm text-gray-500">
                              
                            </p>
                          </div>
                        </a>
                        <a
                          href="#approach"
                          className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                        >
                          {/* Heroicon name: outline/chart-bar */}
                          
                          <div className="ml-4">
                            <p className="text-base font-medium text-gray-900">
                              Our Approach
                            </p>
                            <p className="mt-1 text-sm text-gray-500">
                              {/* Get a better understanding of where your traffic is
                              coming from. */}
                            </p>
                          </div>
                        </a>
                        <a
                          href="#mission"
                          className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                        >
                          {/* Heroicon name: outline/cursor-click */}
                          
                          <div className="ml-4">
                            <p className="text-base font-medium text-gray-900">
                              Mission & Vision
                            </p>
                            <p className="mt-1 text-sm text-gray-500">
                             Our Mission and Vision tells you everything about what we stand for
                            </p>
                          </div>
                        </a>
                        <a
                          href="#approach"
                          className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                        >
                          {/* Heroicon name: outline/shield-check */}
                      
                          <div className="ml-4">
                            <p className="text-base font-medium text-gray-900">
                              Our Approach to Outreach
                            </p>
                            <p className="mt-1 text-sm text-gray-500">
                              {/* Your customers' data will be safe and secure. */}
                            </p>
                          </div>
                        </a>
                        <a
                          href="#aspi"
                          className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                        >
                          {/* Heroicon name: outline/view-grid */}
                       
                          <div className="ml-4">
                            <p className="text-base font-medium text-gray-900">
                              Our Aspirations
                            </p>
                            <p className="mt-1 text-sm text-gray-500">
                              {/* Connect with third-party tools that you're already
                              using. */}
                            </p>
                          </div>
                        </a>
                        <a
                          href="#why"
                          className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                        >
                          {/* Heroicon name: outline/refresh */}
                       
                          <div className="ml-4">
                            <p className="text-base font-medium text-gray-900">
                              Why us
                            </p>
                            <p className="mt-1 text-sm text-gray-500">
                              {/* Build strategic funnels that will drive your
                              customers to convert */}
                            </p>
                          </div>
                        </a>
                      </div>
                      <div className="px-5 py-5 bg-gray-50 space-y-6 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">
                       
                      </div>
                    </div>
                  </div>
                </div>
  
                <a
                  href="#services"
                  className="text-base font-medium text-gray-500 hover:text-gray-900"
                >
                  Services
                </a>
                {/* <a
                  href="#partners"
                  className="text-base font-medium text-gray-500 hover:text-gray-900"
                >
                  Our Partners
                </a> */}
                <a
                  href="#guiding"
                  className="text-base font-medium text-gray-500 hover:text-gray-900"
                >
                  Guiding Principles
                </a>
                <div className="relative">
                  {/* Item active: "text-gray-900", Item inactive: "text-gray-500" */}
                  {/* Link to blog */}
                  <Link to={'/errorpage'}
                    
                    className="group text-gray-500 inline-flex items-center text-base font-medium hover:text-gray-900"
                   
                  >
                    <span>Blog</span>
                    {/*
                Heroicon name: solid/chevron-down
  
                Item active: "text-gray-600", Item inactive: "text-gray-400"
              */}
                   
                  </Link>
                  {/*
              'More' flyout menu, show/hide based on flyout menu state.
  
              Entering: "transition ease-out duration-200"
                From: "opacity-0 translate-y-1"
                To: "opacity-100 translate-y-0"
              Leaving: "transition ease-in duration-150"
                From: "opacity-100 translate-y-0"
                To: "opacity-0 translate-y-1"
            */}{" "}
                 
                </div>
              </nav>
              <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
               
                <a
                  href="#contact"
                  className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-green-forth hover:bg-white hover:text-green-forth"
                >
                  Contact Us
                </a>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  export default Header;

  
