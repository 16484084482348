import React from 'react'
import '../style/style.css'

function Why() {
  return (
    <div id='about' className='why h-84 w-full font-bold my-4 ml-6 mb-6 mr-6'>
    <div className='about-section h-64 w-full px-40 flex items-center justify-center my-6 p-4 flex-col'>
    <h1 className='text-4xl font-extrabold text-white pb-8'>About Us</h1>
        <span className='text-white'>At Nature Guard Ltd., we're on a mission to address two urgent environmental challenges: water pollution and forest degradation. By tackling these issues head-on, we're not only enhancing Earth's health and beauty, but also creating economic opportunities for local communities.</span>
       {/* change div to a to be a link */}
        <div 
              className="mt-2 ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-second-color bg-white hover:bg-second-color hover:text-white"
            >
              Read More
            </div>
    </div>

</div>
  )
}

export default Why