import React from 'react'
import "../style/style.css"
function Mvision() {
  return (
    <div id='mission' className='card-vision mt-4 lg:rounded rounded-sm items-center justify-center bg-white w-full xl:flex lg:flex lg:flex-row md:flex sm:items-center sm:flex-col'>
        <div className='mvision flex bg-green-forth text-left rounded-lg flex-col items-center py-4 lg:m-10 lg:p-10 sm:p-2 md:m-2 lg:w-1/2'>
            <h1 className='text-4xl text-white font-extrabold mr-90 lg:pb-8 '>Mission</h1>
            <p className='lg:text-lg text-white lg:mr-36 ml-10 font-bold'>Preserving and restoring our planet's water bodies and forests through sustainable solutions, empowering communities, and fostering a harmonious coexistence between nature and humanity.</p>
        </div>
        <div className='h-full flex bg-white rounded-lg flex-col items-center p-3 lg:m-10 lg:p-10 md:p-5 lg:w-1/2'>
             <h1 className='text-4xl font-extrabold text-green-forth lg:pb-8 pt-5 pb-2'>Vision</h1>
            <p className='text-lg text-green-forth font-bold md:px-5'>Leading the global charge in environmental restoration, setting unprecedented benchmarks for sustainability, innovation, and positive planetary impact</p>
            
        </div>
    </div>
  )
}

export default Mvision