import axios from "axios";
import Header from "../../components/Header";
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import AddComment from "./AddComment";
import AllComments from "./AllComments";
function BlogDetail() {
  const params = useParams();
  const id = params._id
  const [posts, setPosts] = useState([]);
  // const [setIsloading] = useState(false);
 

  useEffect(() => {
    const baseURL = `http://localhost:8000/post/${id}`;
    const getPost = async () => {
      try {
        let res = await axios.get(baseURL);
        setPosts(res.data);
        console.log(res.data);
        
      } catch (error) {
        
      }
    };
    getPost();
  }, [id]);

  const cat = posts.category;
  const tit = posts.title;
  const user1 = posts.username;
  const desc = posts.description;
  const image = posts.image
  

// console.log(length1)
  return (
    <div>
      <Header />
      <div className="mt-6 bg-gray-50">
        <div className=" px-10 py-6 mx-auto">
          <div className="max-w-6xl px-10 py-6 mx-auto bg-gray-50">
            <div
              className="block transition duration-200 ease-out transform hover:scale-110"
            >
              <img
              alt="img"
                className="object-cover w-full shadow-sm h-full"
                src={image}
              />
            </div>

            <div className="flex items-center justify-start mt-4 mb-4">
              <div className="px-2 py-1 font-bold bg-red-400 text-white rounded-lg hover:bg-gray-500 mr-4">
                {cat}
              </div>
            </div>
            <div className="mt-2">
              <div className="sm:text-3xl md:text-3xl lg:text-3xl xl:text-4xl font-bold text-purple-500  hover:underline">
                {tit}
              </div>

              <div className="flex justify-start items-center mt-2">
                <p className="text-sm text-green-500 font-bold bg-gray-100 rounded-full py-2 px-2 hover:text-red-500">
                  posts.comment.length
                </p>
                <p className="text-sm text-gray-400 font-bold ml-5">Comments</p>
              </div>

              <div className="font-light text-gray-600">
                <div className="flex items-center mt-6 mb-6">
                  <img
                    src="https://avatars.githubusercontent.com/u/71964085?v=4"
                    alt="avatar"
                    className="hidden object-cover w-14 h-14 mx-4 rounded-full sm:block"
                  />
                  <h1 className="font-bold text-gray-700 hover:underline">
                    By {user1}
                  </h1>
                </div>
              </div>
            </div>

            <div className="max-w-4xl px-10  mx-auto text-2xl text-gray-700 mt-4 rounded bg-gray-100">
              <div>
                <p className="mt-2 p-8">{desc}</p>
              </div>
            </div>
          </div>

          <h2 className="text-2xl mt-4 text-gray-500 font-bold text-center">
            Related Posts
          </h2>
          <div className=" grid h-full grid-cols-12 gap-10 pb-10 mt-8 sm:mt-16">
            <div className="grid grid-cols-12 col-span-12 gap-7">
              <div className="flex flex-col items-start col-span-12 overflow-hidden shadow-sm rounded-xl md:col-span-6 lg:col-span-4">
                <div className="block transition duration-200 ease-out transform hover:scale-110">
                  <img
                  alt="img"
                    className="object-cover w-full shadow-sm h-full"
                    src="https://images.unsplash.com/photo-1579621970563-ebec7560ff3e?ixlib=rb-1.2.1&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=1951&amp;q=80"
                  />
                </div>
                <div className="relative flex flex-col items-start px-6 bg-white border border-t-0 border-gray-200 py-7 rounded-b-2xl">
                  <div className="bg-indigo-400 absolute top-0 -mt-3 flex items-center px-3 py-1.5 leading-none w-auto rounded-full text-xs font-medium uppercase text-white">
                    <span>Flask</span>
                  </div>
                  <h2 className="text-base text-gray-500 font-bold sm:text-lg md:text-xl">
                    <a href="#_">
                      Oauth using facebook with flask,mysql,vuejs and tailwind
                      css
                    </a>
                  </h2>
                  {/* <!-- <p class</div>="mt-2 text-sm text-gray-500">Learn how to authenticate users to your application using facebook.</p> --> */}
                </div>
              </div>

              <div className="flex flex-col items-start col-span-12 overflow-hidden shadow-sm rounded-xl md:col-span-6 lg:col-span-4">
                <div className="block transition duration-200 ease-out transform hover:scale-110">
                  <img
                  alt="img"
                    className="object-cover w-full shadow-sm h-full"
                    src="https://images.unsplash.com/photo-1579621970563-ebec7560ff3e?ixlib=rb-1.2.1&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=1951&amp;q=80"
                  />
                </div>
                <div className="relative flex flex-col items-start px-6 bg-white border border-t-0 border-gray-200 py-7 rounded-b-2xl">
                  <div className="bg-red-400 absolute top-0 -mt-3 flex items-center px-3 py-1.5 leading-none w-auto rounded-full text-xs font-medium uppercase text-white">
                    <span>Django</span>
                  </div>
                  <h2 className="text-base text-gray-500 font-bold sm:text-lg md:text-xl">
                    <a href="#_">
                      Authenticating users with email verification in Django
                      apps
                    </a>
                  </h2>
                  {/* <!-- <p class="mt-2 text-sm text-gray-500">Learn how to authenticate users to your web application by sending secure links to their email box.</p> --> */}
                </div>
              </div>

              <div className="flex flex-col items-start col-span-12 overflow-hidden shadow-sm rounded-xl md:col-span-6 lg:col-span-4">
                <div className="block transition duration-200 ease-out transform hover:scale-110">
                  <img
                  alt="img"
                    className="object-cover w-full shadow-sm h-full"
                    src="https://images.unsplash.com/photo-1579621970563-ebec7560ff3e?ixlib=rb-1.2.1&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=1951&amp;q=80"
                  />
                </div>
                <div className="relative flex flex-col items-start px-6 bg-white border border-t-0 border-gray-200 py-7 rounded-b-2xl">
                  <div className="bg-purple-500 absolute top-0 -mt-3 flex items-center px-3 py-1.5 leading-none w-auto rounded-full text-xs font-medium uppercase text-white">
                    <span>Flask</span>
                  </div>
                  <h2 className="text-base text-gray-500 font-bold sm:text-lg md:text-xl">
                    <a href="#_">
                      Creating user registration and authentication system in
                      flask
                    </a>
                  </h2>
                  {/* <!-- <p class="mt-2 text-sm text-gray-500">Learn how to authenticate users to your application using flask and mysql db.</p> --> */}
                </div>
              </div>
            </div>
          </div>

          {/* <!--form form comments--> */}

          <div className="max-w-4xl py-16 xl:px-8 flex justify-center mx-auto">
          <AddComment />
          </div>

          {/* <!--comments--> */}

          
            {/* <!--comment 1--> */}
            <div className="max-w-4xl px-10 py-16 mx-auto   bg-white min-w-screen animation-fade animation-delay  md:px-0 sm:px-8 xl:px-5">
    <p className="mt-1 text-2xl font-bold text-left text-gray-800 sm:mx-6 sm:text-2xl md:text-3xl lg:text-4xl sm:text-center">
      All comments on this post
    </p>
            <AllComments/>

          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogDetail;
