import React from 'react'
import "../style/style.css"
function About() {
  return (
    <div id="why" className='h-2/5 w-full font-bold mt-12 ml-6 mr-6'>
      {/* gradient-to-r from-white-third to-sec-white */}
        {/* <div className='about-section h-1/2 w-1/2 flex items-center m-6 p-4 flex-col'>

        <h1 className='text-4xl font-extrabold text-blue-900 pb-8'>About Us</h1>
            <span className='text-white'>At Nature Guard Ltd., we're on a mission to address two urgent environmental challenges: water pollution and forest degradation. By tackling these issues head-on, we're not only enhancing Earth's health and beauty, but also creating economic opportunities for local communities.</span>
            <a
                  href="#"
                  className="mt-2 ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-second-color bg-white hover:bg-second-color hover:text-white"
                >
                  Read More
                </a>
        </div> */}

        <div className='about-section h-1/2 w-full flex items-center mx-4 my-4 p-4 flex-col'>
        <h1 className='text-4xl font-extrabold text-white-third pb-8'>why Us?</h1>
            <span className='text-sec-white text-lg font-normal'>Our strength lies in our dedication to sustainability and innovation.</span>
            <span className='text-sec-white text-lg font-normal'> We stand apart from competitors by redefining the concept of environmental restoration. </span>
            <span className='text-sec-white text-lg font-normal'> Our commitment to cleaner practices sets new industry standards.</span>
            <div
                  
                  className="mt-4 ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-second-color bg-sec-white hover:bg-second-color hover:text-white"
                >
                  Read More
                </div>
        </div>
    </div>
  )
}

export default About