// // import { Carousel, Typography, Button } from "@material-tailwind/react";
// //  import city from "../assets/city.jpg"
// // const  Hero = () => {
// //   return (
// //     <Carousel loop="true" className="rounded-xl">
// //       <div className="relative h-90 w-full">
// //         <img
// //         src={city}
// //           // src="https://images.unsplash.com/photo-1493246507139-91e8fad9978e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2940&q=80"
// //           alt="image 2"
// //           className="h-90 w-full object-cover"
// //         />
// //         <div className="absolute inset-0 flex h-70 w-full items-center bg-black/75">
// //           <div className="w-3/4 pl-12 md:w-2/4 md:pl-20 lg:pl-32">
// //             <Typography
// //               variant="h1"
// //               color="white"
// //               className="mb-4 text-3xl md:text-4xl lg:text-5xl"
// //             >
// //               The Beauty of Nature
// //             </Typography>
// //             <Typography
// //               variant="lead"
// //               color="white"
// //               className="mb-12 opacity-80"
// //             >
// //               It is not so much for its beauty that the forest makes a claim
// //               upon men&apos;s hearts, as for that subtle something, that quality
// //               of air that emanation from old trees, that so wonderfully changes
// //               and renews a weary spirit.
// //             </Typography>
// //             <div className="flex gap-2">
// //             <button class="rounded-lg px-4 py-2 bg-white text-black hover:bg-blue-600 duration-300">EXPLORE</button>
// //             <button class="rounded-lg px-4 py-2 bg-nav-bg text-white hover:bg-blue-600 duration-300">GALLERY</button>
// //             </div>
// //           </div>
// //         </div>
// //       </div>

// //     </Carousel>
// //   );
// // }
// // export default Hero;
// import city from "../assets/city.jpg"
// import "react-responsive-carousel/lib/styles/carousel.min.css";
// import pic2 from "../assets/felix-fuchs-Ud7Rc7QKxuc-unsplash.jpg"
// import pic3 from "../assets/john-staton-gXnnOI5NtGU-unsplash (1).jpg"
// import pic4 from "../assets/markus-spiske-hxmi7JXvXYo-unsplash.jpg"
// var React = require('react');
// var ReactDOM = require('react-dom');

// var Carousel = require('react-responsive-carousel').Carousel;

// var Hero = ()=>{

//         return (
//             <Carousel showArrows={true} infiniteLoop useKeyboardArrows autoPlay className="h-full z-0" dynamicHeight={false}>
//                 <div className="h-screen relative">
//                     <img src={city} />
//                     <p className="legend">Legend 1</p>
//                 </div>
//                 <div className="h-screen relative">
//                     <img src={pic2}/>
//                     <p className="legend">Legend 2</p>
//                 </div>
//                 <div className="h-screen relative">
//                     <img src={pic3} />
//                     <p className="legend">Legend 3</p>
//                 </div>
//                 <div className="h-autos relative">
//                     <img src={pic4} />
//                     <p className="legend">Legend 4</p>
//                 </div>

//             </Carousel>
//         );
//     }
// export default Hero

import React from "react";
import "../style/style.css";
import "../style/blob.scss";

function Hero() {
  return (
    <div className="hero-back h-screen">
      <div ClassName="leanding-normal tracking-wide">
        <div className="grad w-full h-48 relative top-0 mb-12 pt-16">
          <h1 className="style1 mx-12 mb-4 text-white text-5xl sm:mb-1 sm:text-2xl lg:text-6xl text-center">
            NATURE GUARD LTD
          </h1>
          <h3 className="style2 mx-4 mb-10 text-white text-xl lg:text-2xl text-center">
            Guarding Nature, Forging a Better Future
          </h3>
        </div>

        <div className="container mx-auto w-full overflow-hidden relative">
          <div className="w-full h-full absolute">
            <div className="style2 w-1/4 h-full absolute z-10 left-0"></div>
            <div className="style3 w-1/4 h-full absolute z-10 right-0"></div>
          </div>

          <div className="carousel-items mt-20 flex items-center justify-center style4">
            <div className="carousel-focus flex items-center flex-col relative bg-white mx-3 my-5 px-1 py-1  shadow-lg style5">
              <img
                src="https://res.cloudinary.com/dykjpcitp/image/upload/v1695995330/nature%20guard/ideogram_16_.jpeg_fxoaes.jpg"
                className=""
                alt=""
              />
              {/* <svg className="fill-current text-teal-400 hover:text-teal-500 cursor-pointer h-12 w-12 absolute top-0 right-0 mt-2 -mr-5" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M11.5 0C17.847 0 23 5.153 23 11.5S17.847 23 11.5 23 0 17.847 0 11.5 5.153 0 11.5 0zm0 1C17.295 1 22 5.705 22 11.5S17.295 22 11.5 22 1 17.295 1 11.5 5.705 1 11.5 1zm.5 10h6v1h-6v6h-1v-6H5v-1h6V5h1v6z"/></svg>
                 <button class="absolute top-0 right-0 bg-teal-400 rounded-full px-1 py-0 font-bold text-lg">+</button>
                <span className="text-teal-400 font-bold text-xl mb-3">Sup3r-Us3r</span>
                <img className="h-16 w-16 rounded-full shadow-2xl" src="https://pbs.twimg.com/profile_images/830533062807191552/TbkWKnnv_400x400.jpg" alt="Img"/>
                <p className="mt-3 text-gray-600 text-center">"Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod, quibusdam!"</p>
                <button className="mt-4 mb-2 bg-teal-400 rounded-full px-12 py-1 text-gray-100 font-semibold hover:bg-teal-300 focus:outline-none">Button</button> */}
            </div>

            <div className="carousel-focus flex items-center flex-col relative bg-white mx-5 my-10 px-1 py-1 shadow-lg style6">
              <img
                src="https://res.cloudinary.com/dykjpcitp/image/upload/v1695995330/nature%20guard/ideogram_2_.jpeg_mn0oxt.jpg"
                className=""
                alt=""
              />
              {/* <svg className="fill-current text-teal-400 hover:text-teal-500 cursor-pointer h-12 w-12 absolute top-0 right-0 mt-2 -mr-5" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M11.5 0C17.847 0 23 5.153 23 11.5S17.847 23 11.5 23 0 17.847 0 11.5 5.153 0 11.5 0zm0 1C17.295 1 22 5.705 22 11.5S17.295 22 11.5 22 1 17.295 1 11.5 5.705 1 11.5 1zm.5 10h6v1h-6v6h-1v-6H5v-1h6V5h1v6z"/></svg> */}
              {/* <!-- <button className="absolute top-0 right-0 bg-teal-400 rounded-full px-1 py-0 font-bold text-lg">+</button> --> */}
              {/* <p className="text-teal-400 font-bold text-xl mb-3">Sup3r-Us3r</p> */}
              {/* <img className="h-16 w-16 rounded-full shadow-2xl" src="https://pbs.twimg.com/profile_images/830533062807191552/TbkWKnnv_400x400.jpg" alt="Img"/> */}
              {/* <p className="mt-3 text-gray-600 text-center">"Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod, quibusdam!"</p> */}
              {/* <button className="mt-4 mb-2 bg-teal-400 rounded-full px-12 py-1 text-gray-100 font-semibold hover:bg-teal-300 focus:outline-none">Button</button> */}
            </div>

            <div className="carousel-focus flex items-center flex-col relative bg-white mx-5 my-10 px-1 py-1 shadow-lg style7">
              <img
                src="https://res.cloudinary.com/dykjpcitp/image/upload/v1695995327/nature%20guard/ideogram_15_.jpeg_xfzz3p.jpg"
                className=""
                alt=""
              />
              {/* <svg className="fill-current text-teal-400 hover:text-teal-500 cursor-pointer h-12 w-12 absolute top-0 right-0 mt-2 -mr-5" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M11.5 0C17.847 0 23 5.153 23 11.5S17.847 23 11.5 23 0 17.847 0 11.5 5.153 0 11.5 0zm0 1C17.295 1 22 5.705 22 11.5S17.295 22 11.5 22 1 17.295 1 11.5 5.705 1 11.5 1zm.5 10h6v1h-6v6h-1v-6H5v-1h6V5h1v6z"/></svg> */}
              {/* <!-- <button className="absolute top-0 right-0 bg-teal-400 rounded-full px-1 py-0 font-bold text-lg">+</button> --> */}
              {/* <p className="text-teal-400 font-bold text-xl mb-3">Sup3r-Us3r</p> */}
              {/* <img className="h-16 w-16 rounded-full shadow-2xl" src="https://pbs.twimg.com/profile_images/830533062807191552/TbkWKnnv_400x400.jpg" alt="Img"/> */}
              {/* <p className="mt-3 text-gray-600 text-center">"Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod, quibusdam!"</p> */}
              {/* <button className="mt-4 mb-2 bg-teal-400 rounded-full px-12 py-1 text-gray-100 font-semibold hover:bg-teal-300 focus:outline-none">Button</button> */}
            </div>

            <div className="carousel-focus flex items-center flex-col relative bg-white mx-5 my-10 px-1 py-1 shadow-lg style8">
              <img
                src="https://res.cloudinary.com/dykjpcitp/image/upload/v1695995323/nature%20guard/ideogram_3_.jpeg_hggtuw.jpg"
                className=""
                alt=""
              />
              {/* <svg className="fill-current text-teal-400 hover:text-teal-500 cursor-pointer h-12 w-12 absolute top-0 right-0 mt-2 -mr-5" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M11.5 0C17.847 0 23 5.153 23 11.5S17.847 23 11.5 23 0 17.847 0 11.5 5.153 0 11.5 0zm0 1C17.295 1 22 5.705 22 11.5S17.295 22 11.5 22 1 17.295 1 11.5 5.705 1 11.5 1zm.5 10h6v1h-6v6h-1v-6H5v-1h6V5h1v6z"/></svg> */}
              {/* <!-- <button className="absolute top-0 right-0 bg-teal-400 rounded-full px-1 py-0 font-bold text-lg">+</button> --> */}
              {/* <p className="text-teal-400 font-bold text-xl mb-3">Sup3r-Us3r</p> */}
              {/* <img className="h-16 w-16 rounded-full shadow-2xl" src="https://pbs.twimg.com/profile_images/830533062807191552/TbkWKnnv_400x400.jpg" alt="Img"/> */}
              {/* <p className="mt-3 text-gray-600 text-center">"Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod, quibusdam!"</p> */}
              {/* <button clasNames="mt-4 mb-2 bg-teal-400 rounded-full px-12 py-1 text-gray-100 font-semibold hover:bg-teal-300 focus:outline-none">Button</button> */}
            </div>

            <div className="carousel-focus flex items-center flex-col relative bg-white mx-5 my-10 px-1 py-1 shadow-lg style9">
              <img
                src="https://res.cloudinary.com/dykjpcitp/image/upload/v1695995319/nature%20guard/ideogram_5_.jpeg_rlpkby.jpg"
                className=""
                alt=""
              />
              {/* <svg className="fill-current text-teal-400 hover:text-teal-500 cursor-pointer h-12 w-12 absolute top-0 right-0 mt-2 -mr-5" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M11.5 0C17.847 0 23 5.153 23 11.5S17.847 23 11.5 23 0 17.847 0 11.5 5.153 0 11.5 0zm0 1C17.295 1 22 5.705 22 11.5S17.295 22 11.5 22 1 17.295 1 11.5 5.705 1 11.5 1zm.5 10h6v1h-6v6h-1v-6H5v-1h6V5h1v6z"/></svg> */}
              {/* <!-- <button className="absolute top-0 right-0 bg-teal-400 rounded-full px-1 py-0 font-bold text-lg">+</button> --> */}
              {/* <p className="text-teal-400 font-bold text-xl mb-3">Sup3r-Us3r</p> */}
              {/* <img className="h-16 w-16 rounded-full shadow-2xl" src="https://pbs.twimg.com/profile_images/830533062807191552/TbkWKnnv_400x400.jpg" alt="Img"/> */}
              {/* <p className="mt-3 text-gray-600 text-center">"Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod, quibusdam!"</p> */}
              {/* <button className="mt-4 mb-2 bg-teal-400 rounded-full px-12 py-1 text-gray-100 font-semibold hover:bg-teal-300 focus:outline-none">Button</button> */}
            </div>

            <div className="carousel-focus flex items-center flex-col relative bg-white mx-5 my-10 px-1 py-1 shadow-lg style10">
              <img
                src="https://res.cloudinary.com/dykjpcitp/image/upload/v1695995324/nature%20guard/ideogram_9_.jpeg_hoglgz.jpg"
                className=""
                alt=""
              />
              {/* <svg className="fill-current text-teal-400 hover:text-teal-500 cursor-pointer h-12 w-12 absolute top-0 right-0 mt-2 -mr-5" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M11.5 0C17.847 0 23 5.153 23 11.5S17.847 23 11.5 23 0 17.847 0 11.5 5.153 0 11.5 0zm0 1C17.295 1 22 5.705 22 11.5S17.295 22 11.5 22 1 17.295 1 11.5 5.705 1 11.5 1zm.5 10h6v1h-6v6h-1v-6H5v-1h6V5h1v6z"/></svg> */}
              {/* <!-- <button className="absolute top-0 right-0 bg-teal-400 rounded-full px-1 py-0 font-bold text-lg">+</button> --> */}
              {/* <p className="text-teal-400 font-bold text-xl mb-3">Sup3r-Us3r</p> */}
              {/* <img className="h-16 w-16 rounded-full shadow-2xl" src="https://pbs.twimg.com/profile_images/830533062807191552/TbkWKnnv_400x400.jpg" alt="Img"/> */}
              {/* <p className="mt-3 text-gray-600 text-center">"Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod, quibusdam!"</p> */}
              {/* <button className="mt-4 mb-2 bg-teal-400 rounded-full px-12 py-1 text-gray-100 font-semibold hover:bg-teal-300 focus:outline-none">Button</button> */}
            </div>

            <div className="carousel-focus flex items-center flex-col relative bg-white mx-5 my-10 px-1 py-1 shadow-lg style11">
              <img
                src="https://res.cloudinary.com/dykjpcitp/image/upload/v1695995325/nature%20guard/ideogram_11_.jpeg_japzvi.jpg"
                className=""
                alt=""
              />
              {/* <svg className="fill-current text-teal-400 hover:text-teal-500 cursor-pointer h-12 w-12 absolute top-0 right-0 mt-2 -mr-5" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M11.5 0C17.847 0 23 5.153 23 11.5S17.847 23 11.5 23 0 17.847 0 11.5 5.153 0 11.5 0zm0 1C17.295 1 22 5.705 22 11.5S17.295 22 11.5 22 1 17.295 1 11.5 5.705 1 11.5 1zm.5 10h6v1h-6v6h-1v-6H5v-1h6V5h1v6z"/></svg> */}
              {/* <!-- <button className="absolute top-0 right-0 bg-teal-400 rounded-full px-1 py-0 font-bold text-lg">+</button> --> */}
              {/* <p className="text-teal-400 font-bold text-xl mb-3">Sup3r-Us3r</p> */}
              {/* <img className="h-16 w-16 rounded-full shadow-2xl" src="https://pbs.twimg.com/profile_images/830533062807191552/TbkWKnnv_400x400.jpg" alt="Img"/> */}
              {/* <p className="mt-3 text-gray-600 text-center">"Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod, quibusdam!"</p> */}
              {/* <button className="mt-4 mb-2 bg-teal-400 rounded-full px-12 py-1 text-gray-100 font-semibold hover:bg-teal-300 focus:outline-none">Button</button> */}
            </div>

            <div className="carousel-focus flex items-center flex-col relative bg-white-green mx-5 my-10 px-1 py-1 shadow-lg style12">
              <img
                src="https://res.cloudinary.com/dykjpcitp/image/upload/v1695995325/nature%20guard/ideogram_1_.jpeg_vz8uqp.jpg"
                className=""
                alt=""
              />
              {/* <svg className="fill-current text-teal-400 hover:text-teal-500 cursor-pointer h-12 w-12 absolute top-0 right-0 mt-2 -mr-5" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M11.5 0C17.847 0 23 5.153 23 11.5S17.847 23 11.5 23 0 17.847 0 11.5 5.153 0 11.5 0zm0 1C17.295 1 22 5.705 22 11.5S17.295 22 11.5 22 1 17.295 1 11.5 5.705 1 11.5 1zm.5 10h6v1h-6v6h-1v-6H5v-1h6V5h1v6z"/></svg> */}
              {/* <!-- <button className="absolute top-0 right-0 bg-teal-400 rounded-full px-1 py-0 font-bold text-lg">+</button> --> */}
              {/* <p className="text-teal-400 font-bold text-xl mb-3">Sup3r-Us3r</p> */}
              {/* <img className="h-16 w-16 rounded-full shadow-2xl" src="https://pbs.twimg.com/profile_images/830533062807191552/TbkWKnnv_400x400.jpg" alt="Img"/> */}
              {/* <p className="mt-3 text-gray-600 text-center">"Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod, quibusdam!"</p> */}
              {/* <button className="mt-4 mb-2 bg-teal-400 rounded-full px-12 py-1 text-gray-100 font-semibold hover:bg-teal-300 focus:outline-none">Button</button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
