import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";
// import { useActionData } from 'react-router-dom'
// import {HiArrowNarrowRight} from 'react-icons/hi'
import TimeAgo from "timeago-react";
import "../style/cardStyle.css";
import "../style/style.css"

function News() {
  const [post, setPost] = useState([]);
  // const [isLoading, setIsloading] = useState(false);
  //  const [date1, setDate] = useState()

 
  useEffect(() => {
    const baseURL = "http://localhost:8000/fewPosts";
    const getPost = async () => {
      try {
        let res = await axios.get(baseURL);
        setPost(res.data);
        console.log(res.data);
        
      
      } catch (error) {}
    };
    getPost();
  }, [setPost]);

  return (
    <div className="container mx-auto md:px-6 ">
      <section className="text-center md:text-left bg-white flex flex-col items-center justify-center">
        <h1 className="lg:mb-12 lg:text-4xl text-2xl font-extrabold text-white-third">
          Latest Articles and Blog
        </h1>
        <div className="grid  grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-2 lg:grid-cols-4">
          {
            post.map((post) => {
              return (
                <>
                <Link to={`/blogdetail/${post._id}`}>
                  <figure className="snip1369 rounded-lg">
                    <img src={post.image}  alt="post_img"/>
                    <div className="image">
                      <img src={post.image} alt="pr-sample15" />
                    </div>
                    <figcaption>
                      <h3>{post.username}</h3>
                      <p>
                        
                        <TimeAgo datetime={post.createdAt} locale="gmt" />
                      </p>
                      <p>{post.title}</p>
                    </figcaption>

                    <span className="read-more ">
                      Read More
                      <FaArrowRight className="relative top-4 left-2" />
                    </span>
                  </figure>
                </Link>
                </>
              );
            })}
        </div>
      </section>
    </div>
  );
}

export default News;
