import "../../style/style.css";
import React from "react";
import TimeAgo from "timeago-react";
import { Link } from "react-router-dom";
function HeroCard(props) {
  const category = props.category;
  const title = props.title;
  const username = props.username;
  const date = props.date;
  const id = props._id;
  const image = props.image;
  return (
    <>
      <Link
        classNames="a1 mb-4 md:mb-0 w-full md:w-2/3 relative rounded inline-block"
        to={`/blogdetail/${id}`}
      >
        <div classNames="conta1 absolute left-0 bottom-0 w-full h-full z-10"></div>
        <img src={image} alt="img" />
        <div classNames="p-4 absolute bottom-0 left-0 z-20">
          <span classNames="px-4 py-1 bg-black text-gray-200 inline-flex items-center justify-center mb-2">
            {category}
          </span>
          <h2 classNames="text-4xl font-semibold text-deep-green leading-tight">
            {title}
          </h2>
          <div classNames="flex mt-3">
            <img
              src="https://randomuser.me/api/portraits/men/97.jpg"
              alt="hero_img"
              classNames="h-10 w-10 rounded-full mr-2 object-cover"
            />
            <div>
              <p classNames=" bg-white flex justify-center font-bold text-deep-green text-sm">
                {" "}
                {username}{" "}
              </p>
              <p classNames="font-bold bg-green-forth text-white mt-2 p-2 text-xs">
                <TimeAgo datetime={date} locale="gmt" />
              </p>
            </div>
          </div>
        </div>
      </Link>
    </>
  );
}

export default HeroCard;
