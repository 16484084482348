import React from "react";
import AppLayout from "./layout/AppLayout";
import './index.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import BlogPage from './blog/layout/BlogPage'
import BlogDetail from "./blog/components/BlogDetail";
import ErrorPage from "./components/ErrorPage";


function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<AppLayout/>}></Route>
      <Route path="/blog" element={<BlogPage/>}></Route>
      <Route path="/blogdetail/:_id" element={<BlogDetail/>}></Route>
      <Route path="/errorpage" element={<ErrorPage/>}></Route>
    </Routes>
   
   </BrowserRouter>
//   <div > 
//     <AppLayout/>
//  </div>
  );
}

export default App;
