
import '../../style/style.css'
import React, { useEffect, useState } from 'react'

import axios from 'axios'
import HeroCard from './HeroCard'
import { Link } from 'react-router-dom'
function Hero() {
  const baseURL = 'http://localhost:8000/posts'
  useEffect(() => {
    getPost();
  }, [])
  
  const [post, setPost] = useState([]);
  // const [isLoading, setIsloading] = useState(false)

 let p = 1
 const getPost = async ()=>{
  try {
      let res =await axios.get(baseURL);
      
      setPost(res.data)
      console.log(res.data)
      
  } catch (error) {
     
  }
}
 console.log(post)
  return (
    
    <div className="max-w-screen-xl mx-auto">
    {/* <!-- header --> */}
   

    <main className="mt-10">
      <div className="block md:flex md:space-x-2 px-2 lg:p-0">
       
      {/* {isLoading && post.map(e=>{
            return( */}
              <HeroCard id={post[post.length - p]._id} image={post[post.length - p].image}  category={post[post.length - p].category} title={post[post.length - p].title} username={post[post.length - p].username} date={post[post.length - p].createdAt}/>
      {/* // )})}           */}
      <div className="alink w-full md:w-1/3 relative rounded" 
          
          
        >
          <div className="conta1 absolute left-0 top-0 w-full h-full z-10"
           ></div>
             {/* {isLoading && post.map(e=>{
            return( */}
              <HeroCard id={post[post.length - 2]._id} image={post[post.length - 2].image}  category={post[post.length - 2].category} title={post[post.length - 2].title} username={post[post.length - 2].username} date={post[post.length - 2].createdAt}/>
              {/* )})}             */}
         {/* {<HeroCard _id={post[post.length - 2]._id} image={post[post.length - 2].image}  category={post[post.length - 2].category} title={post[post.length - 2].title} username={post[post.length - 2].username} date={post[post.length - 2].createdAt}/>} */}
        </div>
     
    </div>
      <div className="block lg:flex lg:space-x-2 px-2 lg:p-0 sm:mt-10 md:mt-7 lg:mt-10 mb-10">
        {/* <!-- post cards --> */}
        <div className="w-full lg:w-2/3">
          {/* {isLoading && post.map(e=>{
            return(
              <Link to={'/blogdetail'} 
              class="block rounded w-full lg:flex  mb-10"
                href="#"
              >
               <div class="conta4 h-48 lg:w-48 flex-none bg-cover text-center overflow-hidden opacity-75"
                 >
                </div>
                <div class="bg-white rounded px-4 flex flex-col justify-between leading-normal">
                  <div>
                    <div class="mt-3 md:mt-0 text-gray-700 font-bold text-2xl mb-2">
                     {post.title}
                    </div>
                    <p class="text-gray-700 text-base">
                     {post.description}
                    </p>
                  </div>
                  <div class="flex mt-3">
                    <img src="https://randomuser.me/api/portraits/women/54.jpg"
                      class="h-10 w-10 rounded-full mr-2 object-cover" />
                    <div>
                      <p class="font-semibold text-gray-700 text-sm capitalize"> {post.username} </p>
                      <p class="text-gray-600 text-xs"> {post.createdAt} </p>
                    </div>
                  </div>
                </div>
              </Link>
    
            )
          })} */}
                  
          

        </div>

        {/* <!-- right sidebar --> */}
        <div className="w-full lg:w-1/3 px-3">
          {/* <!-- topics --> */}
          <div className="mb-4">
            <h5 className="font-bold text-lg uppercase text-gray-700 px-1 mb-2"> Popular Topics </h5>
            <ul>
              <li className="px-1 py-4 border-b border-t border-white hover:border-gray-200 transition duration-300">
                <Link to="/" className="flex items-center text-gray-600 cursor-pointer">
                  <span className="inline-block h-4 w-4 bg-green-300 mr-3"></span>
                  Nutrition
                  <span className="text-gray-500 ml-auto">0 articles</span>
                  <i className='text-gray-500 bx bx-right-arrow-alt ml-1'></i>
                </Link>
              </li>
              <li className="px-1 py-4 border-b border-t border-white hover:border-gray-200 transition duration-300">
                <Link to="/" className="flex items-center text-gray-600 cursor-pointer">
                  <span className="inline-block h-4 w-4 bg-indigo-300 mr-3"></span>
                  Food & Diet
                  <span className="text-gray-500 ml-auto">0 articles</span>
                  <i className='text-gray-500 bx bx-right-arrow-alt ml-1'></i>
                </Link>
              </li>
              <li className="px-1 py-4 border-b border-t border-white hover:border-gray-200 transition duration-300">
                <Link to="/" className="flex items-center text-gray-600 cursor-pointer">
                  <span className="inline-block h-4 w-4 bg-yellow-300 mr-3"></span>
                  Workouts
                  <span className="text-gray-500 ml-auto">0 articles</span>
                  <i className='text-gray-500 bx bx-right-arrow-alt ml-1'></i>
                </Link>
              </li>
              <li className="px-1 py-4 border-b border-t border-white hover:border-gray-200 transition duration-300">
                <Link to="/" className="flex items-center text-gray-600 cursor-pointer">
                  <span className="inline-block h-4 w-4 bg-blue-300 mr-3"></span>
                  Immunity
                  <span className="text-gray-500 ml-auto">0 articles</span>
                  <i className='text-gray-500 bx bx-right-arrow-alt ml-1'></i>
                </Link>
              </li>
            </ul>
          </div>

          {/* <!-- divider --> */}
          <div className="border border-dotted"></div>

          {/* <!-- subscribe --> */}
          <div className="p-1 mt-4 mb-4">
            <h5 className="font-bold text-lg uppercase text-gray-700 mb-2"> Subscribe </h5>
            <p className="text-gray-600">
              Subscribe to our newsletter. We deliver the best health related articles to your inbox
            </p>
            <input placeholder="your email address"
              className="text-gray-700 bg-gray-100 rounded-t hover:outline-none p-2 w-full mt-4 border" />
            <button className="px-4 py-2 bg-green-forth text-gray-200 rounded-b w-full capitalize tracking-wide">
              Subscribe
            </button>
          </div>
          <div className="border border-dotted"></div>
    </div>      
    </div>
    </main>
 
  </div>
  
  )
}

export default Hero